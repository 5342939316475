<template>
	<Modal
		:title="title"
		:value="visible"
		width="700"
		:mask-closable="false"
		style="overflow-y: auto"
		@on-cancel="onCancel"
	>
		<Spin fix v-if="loading"></Spin>
		<Form
			v-if="visible"
			:model="formData"
			ref="form"
			label-position="top"
			:rules="rules"
		>
			<Row :gutter="32" class="staff-add">
				<!--用户名-->
				<Col span="12">
					<FormItem label="用户名(不可修改)" prop="username">
						<Input
							:disabled="!!id"
							v-model.trim="formData.username"
							placeholder="请输入邮箱地址"
						/>
					</FormItem>
				</Col>
				<!--真实姓名-->
				<Col span="12">
					<FormItem label="真实姓名" prop="realName">
						<Input
							v-model.trim="formData.realName"
							placeholder="请输入真实姓名"
							readonly
							@on-focus="e => e.target.removeAttribute('readonly')"
						/>
					</FormItem>
				</Col>
				<!--密码-->
				<template v-if="!id">
					<Col span="12">
						<FormItem
							label="密码（6-16位，包含大、小写字母与数字）"
							prop="password"
						>
							<Input
								v-model.trim="formData.password"
								type="password"
								autocomplete="off"
								placeholder="请输入密码"
								readonly
								@on-focus="e => e.target.removeAttribute('readonly')"
							/>
						</FormItem>
					</Col>
					<Col span="12">
						<FormItem label="确认密码" prop="confirmPassword">
							<Input
								v-model.trim="formData.confirmPassword"
								autocomplete="off"
								type="password"
								placeholder="请输入确认密码"
							/>
						</FormItem>
					</Col>
				</template>
				<!--手机号码-->
				<Col span="12">
					<FormItem label="手机号码" prop="phone">
						<Input v-model.trim="formData.phone" placeholder="请输入手机号码" />
					</FormItem>
				</Col>
				<template v-if="type !== 'personalInfo'">
					<!--组织-->
					<Col span="12">
						<FormItem label="组织" prop="orgId">
							<Select
								v-model="formData.orgId"
								filterable
								clearable
								@on-change="orgId => handleOrganizationChange(orgId)"
								placeholder="请选择组织"
							>
								<Option
									v-for="item in organizationList"
									:key="item.id"
									:title="item.name"
									:value="item.id"
									>{{ item.name }}</Option
								>
							</Select>
						</FormItem>
					</Col>
					<!--部门-->
					<Col span="12">
						<FormItem label="部门" prop="deptId">
							<Select
								v-model="formData.deptId"
								filterable
								clearable
								style="position: relative"
								@on-change="deptId => handleDeptChange(deptId)"
								placeholder="请选择部门"
							>
								<Spin fix size="small" v-if="deptLoading"></Spin>
								<Option
									v-for="item in departmentList"
									:key="item.id"
									:value="item.id"
									>{{ item.name }}</Option
								>
							</Select>
						</FormItem>
					</Col>
					<!--职称-->
					<Col span="12">
						<FormItem label="职称" prop="job">
							<Select
								v-model="formData.job"
								filterable
								clearable
								placeholder="请选择职称"
							>
								<Option
									v-for="item in jobList.leaf"
									:key="item.id"
									:value="item.id"
									>{{ item.name }}</Option
								>
							</Select>
						</FormItem>
					</Col>
				</template>
				<!--性别-->
				<Col span="12">
					<FormItem label="性别" prop="sex">
						<Select v-model="formData.sex" placeholder="请选择性别">
							<Option
								v-for="item in sexList.leaf"
								:key="item.id"
								:value="item.id"
							>
								{{ item.name }}
							</Option>
						</Select>
					</FormItem>
				</Col>
				<!--微信-->
				<Col span="12">
					<FormItem label="微信" prop="wx">
						<Input v-model.trim="formData.wx" placeholder="请输入微信" />
					</FormItem>
				</Col>
				<!--CA编号  只有系統管理員有填写和修改权限,目前仅有儿童医院和使用-->
				<Col span="12">
					<FormItem label="CA编号" prop="caUuid">
						<Input
							:disabled="info.id !== '1' && info.id !== '2'"
							v-model.trim="formData.caUuid"
							:maxlength="50"
							placeholder="请输入CA编号"
						/>
					</FormItem>
				</Col>
				<!--工号-->
				<Col span="12">
					<FormItem label="工号" prop="jobNumber">
						<Input
							v-model.trim="formData.jobNumber"
							:maxlength="50"
							placeholder="请输入工号"
						/>
					</FormItem>
				</Col>
				<Col span="12">
					<FormItem label="是否作为排班负责人">
						<Checkbox v-model="formData.isSchedule"> 作为排班负责人 </Checkbox>
					</FormItem>
				</Col>
				<Col span="24">
					<FormItem
						prop="identityBack"
						:required="isIdentityRequired"
						:show-message="false"
						label="身份证头像面"
					>
						<Upload
							action="/"
							:accept="filetype"
							:show-upload-list="false"
							:before-upload="file => getMD5(file, 'identityBack')"
						>
							<template v-if="formData.identityBack">
								<p>
									<Button style="margin-bottom: 10px">修改身份证头像面</Button>
								</p>
								<img
									:src="getPreviewImg(formData.identityBack)"
									class="preview"
								/>
							</template>
							<div class="upload-btn-wrap" v-else>
								<Button type="primary" shape="circle" icon="md-add"></Button>
								<p>上传身份证头像面</p>
							</div>
						</Upload>
					</FormItem>
				</Col>
				<Col span="24">
					<FormItem
						prop="identityFront"
						:required="isIdentityRequired"
						:show-message="false"
						label="身份证国徽面"
					>
						<Upload
							action="/"
							:accept="filetype"
							:show-upload-list="false"
							:before-upload="file => getMD5(file, 'identityFront')"
						>
							<template v-if="formData.identityFront">
								<p>
									<Button style="margin-bottom: 10px">修改身份证头像面</Button>
								</p>
								<img
									:src="getPreviewImg(formData.identityFront)"
									class="preview"
								/>
							</template>
							<div class="upload-btn-wrap" v-else>
								<Button type="primary" shape="circle" icon="md-add"></Button>
								<p>上传身份证国徽面</p>
							</div>
						</Upload>
					</FormItem>
				</Col>
				<Col span="24" v-if="isIdentityRequired">
					<FormItem
						prop="businessProxy"
						:required="true"
						:show-message="false"
						label="单位委托书"
					>
						<img
							:src="getPreviewImg(formData.businessProxy)"
							class="preview"
							v-if="formData.businessProxy"
						/>
						<Upload
							action="/"
							v-else
							:accept="filetype"
							:show-upload-list="false"
							:before-upload="file => getMD5(file, 'businessProxy')"
						>
							<div class="upload-btn-wrap">
								<Button type="primary" shape="circle" icon="md-add"></Button>
								<p>上传单位委托书</p>
							</div>
						</Upload>
					</FormItem>
				</Col>
			</Row>
			<ExtraForm
				@extraValueChange="(key, value) => handleExtraValueChange(key, value)"
				:list="extraList"
				:data="formData"
			></ExtraForm>
		</Form>
		<div slot="footer">
			<Button style="margin-right: 8px" @click="onCancel">取消 </Button>
			<Button type="primary" @click="onOk" :loading="loading">确定 </Button>
		</div>
	</Modal>
</template>

<script>
import { mapState } from "vuex"
import api from "@/api/setting/staff"
import publicApi from "@/api/public"
import { dataToSha, formDataToMD5 } from "@/utils/util"
import ExtraForm from "@/components/BusinessComponents/ExtendForm/ExtraForm.vue"
import ExtraMixin from "@/mixins/ExtraMixin"

const { apiGet, apiAdd, apiUpdate, apiUpdateSelfInfo, uploadFile } = api
const { publicGetDepartmentList } = publicApi

export default {
	name: "Add",
	mixins: [ExtraMixin],
	components: {
		ExtraForm
	},
	props: {
		visible: {
			default: false
		},
		id: {
			default: ""
		},
		jobList: {
			default: () => ({
				leaf: []
			})
		},
		sexList: {
			default: () => ({
				leaf: []
			})
		},
		organizationList: {
			default: () => []
		},
		type: {
			default: ""
		}
	},
	data() {
		return {
			loading: false,
			deptLoading: false,
			formData: {},
			title: "",
			departmentList: [],
			rules: {
				username: [
					{ required: true, message: "用户名不能为空", trigger: "blur" },
					{ type: "email", message: "用户名必须是邮箱地址", trigger: "blur" }
				],
				realName: {
					required: true,
					message: "真实姓名不能为空",
					trigger: "blur"
				},
				password: [
					{ required: true, message: "密码不能为空" },
					{
						validator: "regexp",
						pattern: /^(?=.*[0-9].*)(?=.*[A-Z].*)(?=.*[a-z].*).{6,16}$/,
						message: "密码为6-16位，需要同时包含大、小写字母与数字",
						trigger: "blur"
					},
					{ validator: this.validatePass, trigger: "blur" }
				],
				confirmPassword: [
					{ required: true, message: "确认密码不能为空" },
					{
						validator: "regexp",
						pattern: /^(?=.*[0-9].*)(?=.*[A-Z].*)(?=.*[a-z].*).{6,16}$/,
						message: "密码为6-16位，需要同时包含大、小写字母与数字",
						trigger: "blur"
					},
					{ validator: this.validatePassRepeat, trigger: "blur" }
				],
				orgId: {
					required: true,
					message: "组织不能为空",
					trigger: "blur change"
				},
				deptId: {
					required: true,
					message: "部门不能为空",
					trigger: "blur change"
				},
				job: { required: false, message: "职称不能为空", trigger: "blur" },
				sex: { required: true, message: "性别不能为空", trigger: "blur" },
				phone: [
					{ required: true, message: "手机号码不能为空", trigger: "blur" },
					{
						...this.rules.mobileNumber,
						message: "手机号码格式不正确",
						trigger: "blur"
					}
				]
			},
			extraList: [],
			filetype: "image/jpg, image/jpeg, image/png, image/bmp"
		}
	},
	computed: {
		isIdentityRequired() {
			const org = this.organizationList.find(
				item => item.id === this.formData.orgId
			)
			return org ? ["101", "102", "103"].indexOf(org.type) > -1 : false
		},
		...mapState("user", ["info"])
	},
	watch: {
		visible() {
			if (this.visible) {
				this.$refs.form && this.$refs.form.resetFields()
				this.formData = {}
				this.departmentList = []
				this.title = "新增用户"
				if (this.id) {
					this.title = "修改用户信息"
					if (this.type === "personalInfo") {
						this.title = "修改基本信息"
					}
					this.$asyncDo(async () => {
						this.loading = true
						const res = await apiGet({ id: this.id })
						if (res) {
							this.getDepartmentByOrganizationId(res.data.orgId)

							this.formData = {
								...res.data,
								isSchedule: !!res.data.isSchedule,
								...JSON.parse(res.data.extraData)
							}
						}
						this.loading = false
					})
				}
			}
		}
	},
	created() {
		this.getExtraList("USER")
	},
	methods: {
		validatePass(rule, value, callback) {
			// 验证密码
			if (value === "") {
				callback(new Error("请输入密码"))
			} else {
				if (this.formData.confirmPassword !== "") {
					this.$refs.form.validateField("confirmPassword")
				}
				callback()
			}
		},
		validatePassRepeat(rule, value, callback) {
			// 验证确认密码
			if (value === "") {
				callback(new Error("请再次输入密码"))
			} else if (value !== this.formData.password) {
				callback(new Error("两次输入密码不一致!"))
			} else {
				callback()
			}
		},
		onCancel() {
			this.$emit("onCancel")
		},
		onOk() {
			if (!this.formData.identityBack && this.isIdentityRequired) {
				this.$Message.error("请上传身份证头像面！")
				return false
			}
			if (!this.formData.identityFront && this.isIdentityRequired) {
				this.$Message.error("请上传身份证国徽面！")
				return false
			}
			if (!this.formData.businessProxy && this.isIdentityRequired) {
				this.$Message.error("请上传单位委托书！")
				return false
			}

			this.$refs.form.validate(valid => {
				if (valid) {
					this.$asyncDo(async () => {
						this.loading = true
						let res = false
						if (this.id) {
							if (this.type === "personalInfo") {
								res = await apiUpdateSelfInfo({
									...this.formData,
									password: dataToSha(this.formData.password),
									userId: this.id,
									extraData: this.getExtraData(),
									isSchedule: this.formData.isSchedule ? 1 : 0
								})
							} else {
								res = await apiUpdate({
									...this.formData,
									password: dataToSha(this.formData.password),
									userId: this.id,
									extraData: this.getExtraData(),
									isSchedule: this.formData.isSchedule ? 1 : 0
								})
							}
						} else {
							res = await apiAdd({
								...this.formData,
								password: dataToSha(this.formData.password),
								extraData: this.getExtraData(),
								isSchedule: this.formData.isSchedule ? 1 : 0
							})
						}
						if (res) {
							this.$emit("onOk")
							this.$Message.success("操作成功！")
						}
						this.loading = false
					})
				}
			})
			return true
		},
		handleOrganizationChange(orgId) {
			this.$set(this.formData, "orgId", orgId)
			this.$set(this.formData, "deptId", "")
			if (!orgId) {
				this.departmentList = []
				return
			}
			this.getDepartmentByOrganizationId(orgId)
		},
		handleDeptChange(deptId) {
			this.$set(this.formData, "deptId", deptId)
		},
		// 根据组织id获取部门列表
		getDepartmentByOrganizationId(orgId) {
			this.deptLoading = true
			this.$asyncDo(async () => {
				const res = await publicGetDepartmentList({
					orgId
				})
				if (res) {
					this.departmentList = res.data
				}
				this.deptLoading = false
			})
		},
		// md5
		getMD5(file, type) {
			if (!this.filetype.includes(file.type)) {
				this.$Message.error("只允许上传格式为jpg,png,bmp！")
				return false
			}
			if (file.size > 1024 * 1024 * 10) {
				this.$Message.error("文件大小不能超过10M！")
				return false
			}
			formDataToMD5(file, md5Str => {
				this.uploadFile(file, { md5: md5Str }, type)
			})
			return false
		},
		// 上传文件
		uploadFile(file, params, type) {
			this.$asyncDo(async () => {
				const fd = new FormData()
				fd.append("file", file)
				const res = await uploadFile(fd, params)
				if (res) {
					this.$Message.success("上传成功!")
					this.$set(this.formData, type, res.data)
				}
			})
		},
		// 获取图片
		getPreviewImg(fileId) {
			return `${this.$baseUrl}/document/attachment/user-audit-file/${fileId}`
		}
	}
}
</script>

<style lang="less">
@import "../../../my-theme/color.less";
.staff-add {
	.ivu-upload-select {
		width: 100% !important;
		.preview {
			max-width: 100%;
		}
	}
	.upload-btn-wrap {
		width: 100%;
		background-color: #f9f9f9;
		padding: 80px 0 50px 0;
		text-align: center;
		cursor: pointer;
		> p {
			margin-top: 15px;
			color: @primary-color;
		}
	}
}
</style>
