<template>
	<Modal
		title="修改密码"
		:value="visible"
		:mask-closable="false"
		@on-cancel="onCancel"
		@on-ok="handleSubmit"
	>
		<Form ref="form" :model="formData" :rules="formRules" v-if="visible">
			<FormItem prop="oldPassword" label="原密码">
				<Input
					placeholder="请输入密码"
					v-model.trim="formData.oldPassword"
					autocomplete="off"
					type="password"
				/>
			</FormItem>
			<FormItem prop="password" label="新密码">
				<Input
					placeholder="请输入新密码"
					v-model.trim="formData.password"
					autocomplete="off"
					type="password"
				/>
			</FormItem>
			<FormItem prop="confirmPassword" label="确认密码">
				<Input
					placeholder="请输入确认密码"
					v-model.trim="formData.confirmPassword"
					autocomplete="off"
					type="password"
				/>
			</FormItem>
		</Form>
		<div slot="footer">
			<Button @click="onCancel">取消</Button>
			<Button type="primary" :loading="loading" @click="handleSubmit"
				>确定</Button
			>
		</div>
	</Modal>
</template>

<script>
import { changePassword } from "@/api/user/user"
import { dataToSha } from "@/utils/util"

export default {
	name: "Password",
	props: ["visible"],
	data() {
		return {
			formData: {},
			loading: false,
			formRules: {
				oldPassword: {
					required: true,
					message: "原密码不能为空",
					trigger: "blur"
				},
				password: [
					{ required: true, message: "密码不能为空" },
					{
						validator: "regexp",
						pattern: /^(?=.*[0-9].*)(?=.*[A-Z].*)(?=.*[a-z].*).{6,16}$/,
						message: "密码为6-16位，需要同时包含大、小写字母与数字",
						trigger: "blur"
					},
					{ validator: this.validatePass, trigger: "blur" }
				],
				confirmPassword: [
					{ required: true, message: "确认密码不能为空" },
					{
						validator: "regexp",
						pattern: /^(?=.*[0-9].*)(?=.*[A-Z].*)(?=.*[a-z].*).{6,16}$/,
						message: "密码为6-16位，需要同时包含大、小写字母与数字",
						trigger: "blur"
					},
					{ validator: this.validatePassRepeat, trigger: "blur" }
				]
			}
		}
	},
	watch: {
		visible(val) {
			if (val) {
				this.formData = {}
			}
		}
	},
	methods: {
		validatePass(rule, value, callback) {
			// 验证密码
			if (value === "") {
				callback(new Error("请输入密码"))
			} else {
				if (this.formData.confirmPassword !== "") {
					this.$refs.form.validateField("confirmPassword")
				}
				callback()
			}
		},
		validatePassRepeat(rule, value, callback) {
			// 验证确认密码
			if (value === "") {
				callback(new Error("请再次输入密码"))
			} else if (value !== this.formData.password) {
				callback(new Error("两次输入密码不一致!"))
			} else {
				callback()
			}
		},
		onCancel() {
			this.$emit("onCancel")
		},
		// 提交
		handleSubmit() {
			this.$refs.form.validate(valid => {
				if (valid) {
					const { password, oldPassword } = this.formData
					if (oldPassword === password) {
						this.$Message.error("新密码和旧密码不能一样！")
						return false
					}
					this.$asyncDo(async () => {
						this.loading = true
						const res = await changePassword({
							oldPwd: dataToSha(oldPassword),
							password: dataToSha(password)
						})
						if (res) {
							this.$Message.success("操作成功！")
							this.$emit("onCancel")
						}
						this.loading = false
					})
				}
				return false
			})
		}
	}
}
</script>
